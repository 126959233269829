@font-face {
  font-family: "Dancing Script";
  src: url("../fonts/Dancing_Script/DancingScript-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Indie Flower";
  src: url("../fonts/Indie_Flower/IndieFlower-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src:  url("../fonts/MaterialIcons/MaterialIcons-Regular.woff") format('woff'), /* For IE6-8 */
        url("../fonts/MaterialIcons/MaterialIcons-Regular.woff2") format('woff2'),
        url("../fonts/MaterialIcons/MaterialIcons-Regular.ttf") format('truetype') ;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

html{
  height: 100%;
}

body{
  //background-color: #FFFAF0;
  background-color: #FFFFFF;
  margin: 0;
  padding: 0;
}

.bg-alternate{
  background-color: #eed3e4;
}

#content{
  min-height: 100vh;
}

.header-image{
  position: relative;
  text-align: center;
  color: white;
}

.image-text-center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50pt;
  text-shadow: black 2px 1px;
}

.navbar{
  border-bottom: 1px solid black;
  //background: linear-gradient(to right, #FFFFFF 25%, #FEC8D8);
  background-color: #FFFFFF;
  @media (max-width: 991.98px) {
    text-align: center;
  }
}


.nav-item{
  $bgColor: white;
  $plainColor: #000000;
  $fadeColor: #c8c9cd;

  @media (max-width: 991.98px) {
    &:hover{
      background-color: $fadeColor;
    }

    &#first-nav-item{
      border-top: 1px solid $plainColor;
    }
  }
}

.custom-footer{
  background-color: #383838;
  margin: 0;
  padding: 0;
  bottom: 0;
  left:0;
  right: 0;
}

.beforeTransitionVisibility, .beforeTransitionVisibilityScroll{
  visibility: hidden;
  opacity: 0;
}

.transitionVisibility{
  visibility: visible;
  opacity: 1;


  //Pour i allant de 1 à 5
  @for $i from 1 through 5 {
    //On créé des classe 'transitionVisibility-i
    &-#{$i}{
      //La transition dure i seconde(s)
      transition: visibility 0s linear 0s, opacity #{$i * 1000}ms;
    }
  }

}


